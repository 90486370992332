import * as _isGlob3 from "is-glob";

var _isGlob2 = "default" in _isGlob3 ? _isGlob3.default : _isGlob3;

import * as _globParent3 from "glob-parent";

var _globParent2 = "default" in _globParent3 ? _globParent3.default : _globParent3;

import * as _path3 from "path";

var _path2 = "default" in _path3 ? _path3.default : _path3;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parseDependency;

var _isGlob = _interopRequireDefault(_isGlob2);

var _globParent = _interopRequireDefault(_globParent2);

var _path = _interopRequireDefault(_path2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // Based on `glob-base`
// https://github.com/micromatch/glob-base/blob/master/index.js


function parseGlob(pattern) {
  let glob = pattern;
  let base = (0, _globParent).default(pattern);

  if (base !== ".") {
    glob = pattern.substr(base.length);

    if (glob.charAt(0) === "/") {
      glob = glob.substr(1);
    }
  }

  if (glob.substr(0, 2) === "./") {
    glob = glob.substr(2);
  }

  if (glob.charAt(0) === "/") {
    glob = glob.substr(1);
  }

  return {
    base,
    glob
  };
}

function parseDependency(normalizedFileOrGlob) {
  if (normalizedFileOrGlob.startsWith("!")) {
    return null;
  }

  let message;

  if ((0, _isGlob).default(normalizedFileOrGlob)) {
    let {
      base,
      glob
    } = parseGlob(normalizedFileOrGlob);
    message = {
      type: "dir-dependency",
      dir: _path.default.resolve(base),
      glob
    };
  } else {
    message = {
      type: "dependency",
      file: _path.default.resolve(normalizedFileOrGlob)
    };
  } // rollup-plugin-postcss does not support dir-dependency messages
  // but directories can be watched in the same way as files


  if (message.type === "dir-dependency" && process.env.ROLLUP_WATCH === "true") {
    message = {
      type: "dependency",
      file: message.dir
    };
  }

  return message;
}

export default exports;