import * as _fs3 from "fs";

var _fs2 = "default" in _fs3 ? _fs3.default : _fs3;

import * as _path3 from "path";

var _path2 = "default" in _path3 ? _path3.default : _path3;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = resolveConfigPath;

var _fs = _interopRequireDefault(_fs2);

var _path = _interopRequireDefault(_path2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function isObject(value) {
  return typeof value === "object" && value !== null;
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function isString(value) {
  return typeof value === "string" || value instanceof String;
}

function resolveConfigPath(pathOrConfig) {
  // require('tailwindcss')({ theme: ..., variants: ... })
  if (isObject(pathOrConfig) && pathOrConfig.config === undefined && !isEmpty(pathOrConfig)) {
    return null;
  } // require('tailwindcss')({ config: 'custom-config.js' })


  if (isObject(pathOrConfig) && pathOrConfig.config !== undefined && isString(pathOrConfig.config)) {
    return _path.default.resolve(pathOrConfig.config);
  } // require('tailwindcss')({ config: { theme: ..., variants: ... } })


  if (isObject(pathOrConfig) && pathOrConfig.config !== undefined && isObject(pathOrConfig.config)) {
    return null;
  } // require('tailwindcss')('custom-config.js')


  if (isString(pathOrConfig)) {
    return _path.default.resolve(pathOrConfig);
  } // require('tailwindcss')


  for (const configFile of ["./tailwind.config.js", "./tailwind.config.cjs"]) {
    try {
      const configPath = _path.default.resolve(configFile);

      _fs.default.accessSync(configPath);

      return configPath;
    } catch (err) {}
  }

  return null;
}

export default exports;