import * as _objectHash3 from "object-hash";

var _objectHash2 = "default" in _objectHash3 ? _objectHash3.default : _objectHash3;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = hashConfig;

var _objectHash = _interopRequireDefault(_objectHash2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function hashConfig(config) {
  return (0, _objectHash).default(config, {
    ignoreUnknown: true
  });
}

export default exports;