import _setupTrackingContext2 from "./lib/setupTrackingContext";
import _processTailwindFeatures2 from "./processTailwindFeatures";
import _sharedState2 from "./lib/sharedState";
var exports = {};

var _setupTrackingContext = _interopRequireDefault(_setupTrackingContext2);

var _processTailwindFeatures = _interopRequireDefault(_processTailwindFeatures2);

var _sharedState = _sharedState2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports = function tailwindcss(configOrPath) {
  return {
    postcssPlugin: "tailwindcss",
    plugins: [_sharedState.env.DEBUG && function (root) {
      console.log("\n");
      console.time("JIT TOTAL");
      return root;
    }, function (root, result) {
      (0, _processTailwindFeatures).default((0, _setupTrackingContext).default(configOrPath))(root, result);
    }, _sharedState.env.DEBUG && function (root) {
      console.timeEnd("JIT TOTAL");
      console.log("\n");
      return root;
    }].filter(Boolean)
  };
};

exports.postcss = true;
export default exports;
export const postcss = exports.postcss;